import React from 'react';
import {Grid} from '@mui/material';

function ScrollingLogos({logos}) {
  return (
    <Grid
      container
      style={{
        backgroundColor: '#003671',
        minHeight: '20vh',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center', // Vertical centring
      }}>
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          minWidth: '200%',
          animation: 'marquee 60s linear infinite', // Slowed down to 60 seconds
        }}>
        {[...logos, ...logos].map((logo, index) => (
          <div
            key={index}
            style={{
              display: 'inline-block',
              padding: '40px 30px',
              margin: '0 48px',
              textAlign: 'center',
            }}>
            {/* <img
              src={logo.src}
              alt={logo.alt || `logo-${index}`}
              style={{
                // maxWidth: '12vw', // Increased from 10vw
                maxHeight: '36px', // Increased from 18px
                height: 'auto',
                width: 'auto',
                filter: logo.invert ? 'invert(1) brightness(1000%)' : undefined,
                objectFit: 'contain',
              }}
            /> */}
            <picture>
              <source srcSet={logo.src.replace(/\.(png|jpg|jpeg)$/, '.webp')} type="image/webp" />
              <img
                src={logo.src}
                alt={logo.alt || `logo-${index}`}
                style={{
                  maxHeight: '36px',
                  height: 'auto',
                  width: 'auto',
                  filter: logo.invert ? 'invert(1) brightness(1000%)' : undefined,
                  objectFit: 'contain',
                }}
              />
            </picture>
          </div>
        ))}
      </div>

      <style>
        {`
          @keyframes marquee {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }
        `}
      </style>
    </Grid>
  );
}

export default ScrollingLogos;
